<template>
    <div>
        <div class="mb-4">
            <button class="button is-warning" @click="exportList">Export</button>
        </div>
        <section class="box">
            <b-table
                :data="list"
                :mobile-cards="true"
                :debounce-search="400"
                default-sort-direction="desc"
                :striped="true"
                :loading="loading"
                :paginated="true"
                :per-page="rowsPerPage"
                :total="total"
                backend-sorting
                backend-pagination
                backend-filtering
                @page-change="onPageChange"
                @sort="onSort"
                @filters-change="onFilterChange"
            >
                <b-table-column field="search" label="Mot-clé" sortable>
                    <div slot="default" slot-scope="props">
                        {{ props.row.search }}
                    </div>
                </b-table-column>
                <b-table-column field="created_at" sortable label="Mois de la recherche" :searchable="true">
                    <div slot="default" slot-scope="props">
                        <b-tooltip :label="moment(props.row.created_at).from()">
                            {{ moment(props.row.created_at).format('DD/MM/YYYY') }}
                        </b-tooltip>
                    </div>
                    <div slot="searchable" slot-scope="props">
                        <div class="select is-small">
                            <select v-model="props.filters[props.column.field]">
                                <option :value="null">
                                    Mois
                                </option>
                                <option v-for="(month, idx) in months" :key="'month_' + idx" :value="idx">
                                    {{ month }}
                                </option>
                            </select>
                        </div>
                    </div>
                </b-table-column>
            </b-table>
        </section>
    </div>
</template>

<script>
import Download from '../../utils/download'
import {mapActions} from 'vuex'

export default {
    title: 'layout.last-researches',
    data: () => ({
        months: [],
        list: [],
        loading: false,
        total: 0,
        rowsPerPage: 15,
        page: 1,
        sortField: 'id',
        sortOrder: 'desc',
        filters: []
    }),
    mounted() {
        this.getMonths().then(({months}) => {
            this.months = months
        })
        this.search()
    },
    methods: {
        ...mapActions('searchlog', {
            listLog: 'list',
            getMonths: 'getMonths',
            exportAction: 'export'
        }),
        search() {
            this.loading = true
            this.listLog({
                page: this.page,
                rowsPerPage: this.rowsPerPage,
                sortField: this.sortField,
                sortOrder: this.sortOrder,
                filters: this.filters
            }).then(({log}) => {
                this.list = log.data
                this.total = log.total
                this.loading = false
            })
        },
        onPageChange(page) {
            this.page = page
            this.search()
        },
        onSort(field, order) {
            this.sortField = field
            this.sortOrder = order

            this.search()
        },
        onFilterChange(filter) {
            var filters = {}
            Object.keys(filter).forEach(element => {
                filters[element] = filter[element]
            })
            this.filters = filters
            this.search()
        },
        exportList() {
            this.exportAction({
                page: this.page,
                rowsPerPage: this.rowsPerPage,
                sortField: this.sortField,
                sortOrder: this.sortOrder,
                filters: this.filters
            }).then(response => {
                Download.download(response, 'search_history.xls')
            })
        }
    }
}
</script>

<style></style>
